import React from 'react'
import { graphql } from 'gatsby'
import { Layout, Stack, Main } from '@layout'
import Seo from "@widgets/Seo";
import Divider from "@components/Divider";
import PageTitle from "@components/PageTitle";
import { Card as CardComponent } from 'theme-ui'
import Section from '@components/Section'
import ReactMarkdown from 'react-markdown'


export const pageQuery = graphql`
  query PageSearch(
      $id: String!
  ) {
    pg: strapiPage(id: { eq: $id }) {
        id
        private
        slug
        updatedAt
        title
        body {
            data {
                body
            }
        }
#        rawBody
        thumbnail {
          url
          width
          size
          name
          height
          alternativeText
          caption
        }
        
        seo{
            metaTitle
            metaDescription
        }
    }
  }
`;

const BasicPageGeneratedTemplate = ({ data, ...props }) => {
  console.log(data);
  const title = data.pg.title;
  console.log(title);
  const page_content = data.pg.body.data.body;
    return (
        <Layout {...props}>
            <Seo title={title || data.pg.seo.metaTitle}
                 description={data.pg.seo.metaDescription}
                 thumbnail={data.pg.thumbnail.url}
            />
            <Divider/>
            <Stack>
                <Main>
                    <PageTitle
                        header={title}
                    />
                    <Divider/>
                    <Section>
                        <CardComponent variant='paper'>
                            <Section>
                                <ReactMarkdown
                                    children={page_content}
                                    />

                                {data.pg.seo.metaTitle}
                                <br/>
                                {data.pg.seo.metaDescription}
                                <br/>
                                {data.pg.thumbnail.url}

                            </Section>
                            <Section>
                                {props.children}
                            </Section>
                        </CardComponent>
                    </Section>
                </Main>
            </Stack>
        </Layout>
    )
}
export default BasicPageGeneratedTemplate
